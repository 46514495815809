






















































































import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import {
  InternalPersonalYearEndSummaryCreateOrUpdateDto,
  RoleDto,
  OrganizationUnitDto,
  AttachmentHostType,
} from "@/api/appService";
import api from "@/api";
import { ElForm } from "element-ui/types/form";
import ProjectSelect from "@/components/ProjectSelect/index.vue";
import MultipleUploadFile from "@/components/MultipleUploadFile/index.vue";
import Ueditor from "@/components/Ueditor/index.vue";

@Component({
  name: "InternalPersonalYearEndSummaryEdit",
  components: {
    ProjectSelect,
    MultipleUploadFile,
    Ueditor,
  },
})
export default class InternalPersonalYearEndSummaryEdit extends Vue {
  @Ref() readonly dataForm!: ElForm;
  dataId!: number;

  defaultData: InternalPersonalYearEndSummaryCreateOrUpdateDto = {
    year: undefined,
    role: undefined,
    userName: undefined,
    summary: undefined,
    savedAttachments: [],
    id: 0,
  };
  roleList: string[] = [];
  roleString = "";
  yearList: number[] = [];

  form: InternalPersonalYearEndSummaryCreateOrUpdateDto = {
    ...this.defaultData,
  };

  get hostTypeFile() {
    return AttachmentHostType.Internal_Personal_Year_End_Summary;
  }

  public pickerOptions: any = {
    disabledDate: (time: any) => {
      const minYear = new Date().getFullYear() - 10;
      return (
        time > Date.now() || time.getTime() < new Date(JSON.stringify(minYear))
      );
    },
  };

  created() {
    this.initSelectYear();
    this.getUserRoles();
    this.dataId = Number(this.$route.params.id);
    if (this.dataId) {
      this.fetchDetail();
    }
  }

  initSelectYear() {
    for (let i = 0; i < 30; i++) {
      this.yearList.push(new Date().getFullYear() - i);
    }
  }

  // 获取详情
  async fetchDetail() {
    await api.internalPersonalYearEndSummary
      .get({ id: this.dataId })
      .then((res) => {
        this.form = { ...res };
      });
  }

  getUserRoles() {
    const fullName = this.$store.getters["user/fullName"];
    this.form.userName = fullName;
    const roles = this.$store.getters["user/roles"] as string[];
    roles!.forEach((s) => {
      this.roleList.push(this.formateRoleName(s ?? ""));
    });
    this.roleString = this.roleList.join(",");
  }

  formateRoleName(code: string) {
    switch (code) {
      case "Admin":
        return "管理员";

      case "Evaluation":
        return "评估方";

      case "Partner":
        return "合作方";
      case "Foundation":
        return "基金会管理";
      case "FoundationProject":
        return "项目人员";
      case "Secretary":
        return "秘书长";
      case "Finance":
        return "财务人员";
      default:
        return "";
    }
  }

  cancel() {
    this.$router.go(-1);
  }

  async save() {
    this.form.role = this.roleString;
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.dataId) {
          await api.internalPersonalYearEndSummary.update({
            body: { ...this.form },
          });
        } else {
          await api.internalPersonalYearEndSummary.create({
            body: { ...this.form },
          });
        }
        this.$message.success("更新成功");
        this.$router.go(-1);
      } else {
        this.$message({
          type: "error",
          message: "请检查表单",
        });
      }
    });
  }

  roleRule = {
    year: [
      {
        required: true,
        message: "请选择年份",
        trigger: "blur",
      },
    ],
    userName: [
      {
        required: true,
        message: "请输入填写人",
        trigger: "blur",
      },
    ],
    summary: [
      {
        required: true,
        message: "请输入个人总结内容",
        trigger: "blur",
      },
    ],
  };
}

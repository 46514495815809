
















































import { Component, Ref, Vue } from "vue-property-decorator";
import AttachmentsView from "@/components/AttachmentsView/index.vue";
import {
  InternalPersonalYearEndSummaryDto,
  OrganizationUnitDto,
  AttachmentHostType,
  AuditFlowScope,
} from "@/api/appService";
import api from "@/api";
import moment from "moment";
import AuditBlock, {AuditApi} from "@/components/Audit/AuditBlock.vue";

import ExportWord from "@/components/DownloadWord/index.vue";

@Component({
  name: "InternalPersonalYearEndSummaryDetail",
  components: {
    AttachmentsView,
    AuditBlock,
    ExportWord,
  },
})
export default class InternalPersonalYearEndSummaryDetail extends Vue {

  dataId?: number;
  detail: InternalPersonalYearEndSummaryDto = {
    year: undefined,
    role: undefined,
    userName: undefined,
    summary: undefined,
    id: 0,
  };
  hostType = AttachmentHostType.Internal_Personal_Year_End_Summary;
  selector = "table-detail";
  filename = "个人年终总结";
  styles = "";


  get auditApi(){
    return {
      canAudit: api.internalPersonalYearEndSummary.canAudit,
      userAudit: api.internalPersonalYearEndSummary.audit
    } as AuditApi
  }


  created() {
    if (this.$route.params.id) {
      this.dataId = Number(this.$route.params.id);
      this.fetchDetail();
    }
  }

  // 获取详情
  async fetchDetail() {
    await api.internalPersonalYearEndSummary
      .get({ id: this.dataId })
      .then((res) => {
        this.detail = { ...res };
      });
  }

  // 返回
  private cancel() {
    this.$router.back();
  }
}

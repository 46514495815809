















import {Component, Model, Prop, Vue, Watch} from "vue-property-decorator";
import Base64 from "@/utils/Base64";
import api from "@/api";
import {AttachmentDto, AttachmentHostType} from "@/api/appService";

@Component({
  name: "multipleUploadFile",
})
export default class multipleUploadFile extends Vue {
  @Model("change", {type: Array, default: () => []}) readonly fileIds?:
      | number[]
      | undefined;

  @Prop({required: false})
  hostType!: AttachmentHostType;

  @Prop({required: true})
  hostId!: string | number;

  attachmentList?: AttachmentDto[] = [];
  attachmentIds?: number[] = [];

  created() {
    let hostId = "";
    if (typeof this.hostId === "number") {
      hostId = this.hostId.toString();
    } else {
      hostId = this.hostId;
    }
    api.attachmentService
        .getAttachments({
          hostId: hostId,
          hostType: this.hostType,
        })
        .then((res) => {
          this.attachmentList = res;
          if (this.attachmentList!.length > 0) {
            this.attachmentList!.map((item: any) => {
              item.name = item.fileName;
              this.attachmentIds!.push(item.id);
            });
          }
          this.$emit("change", this.attachmentIds);
        });
  }

  // 上传成功
  async onSuccess(res: any, file: any) {
    if (res.success) {
      this.attachmentIds!.push(res.result.attachmentId);
      this.$emit("change", this.attachmentIds);
    }
  }

  // 删除前
  beforeRemove(file: any) {
    return this.$confirm(`确定移除 ${file.name}？`);
  }

  // 删除成功
  onRemove(file: any) {
    this.$emit("onRemove", file);
    let index = this.attachmentIds!.indexOf(file.id);
    if (index == -1) {
      index = this.attachmentIds!.indexOf(file.response.result.attachmentId);
    }
    this.attachmentIds!.splice(index, 1);
    this.$emit("change", this.attachmentIds);
  }

  onPreview(file: any) {
    window.open(file.url);
  }

  get uploadParameter() {
    return {hostType: this.hostType};
  }

  get headers() {
    return {
      "Abp-OrganizationUnitId": (this as any).$store.getters["user/ouId"] || "",
      "Abp-TenantId": (this as any).$store.getters["app/tenantId"],
      Authorization: `Bearer ${(this as any).$store.getters["user/token"]}`,
      ".AspNetCore.Culture": "c=zh-Hans|uic=zh-Hans",
    };
  }
}
